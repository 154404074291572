import React from "react"
import styled, { css } from 'styled-components'
import SocialLinks from "../components/social-links"

const Footer = styled.footer`
    color: #242424;
`

export default () => (
    <Footer>
        <SocialLinks />
    </Footer>
)