import React from "react"
import styled, { css } from 'styled-components'

const Button = styled.div`
    background-color: #3A44F0;
    width: 150px;
    padding: 22px;
    margin-right: 30px;
    margin-top: 30px;
    border-radius: 30px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.25s ease;
    &:hover {
      background-color: #2a34e2;
    }

  ${props => props.secondary && css`
    background-color: #171717;
    color: #696969;
    &:hover {
        background-color: #121212;
    }
  `}
`;

export default Button;
