import React from "react"
import styled from 'styled-components'

const Navbar = styled.div`
    height: auto;
    display: flex;
    align-items: flex-end;
    color: #353535;
    font-size: 20px;
    & a:hover {
      transition: color 1.5s ease;
      color: #4E58F5;
    }
    @media only screen and (max-width: 600px) {
    height: 90px;
}
`

export default Navbar;