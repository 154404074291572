import React from "react"
import styled from 'styled-components'
import { Link } from "gatsby"
import { FaLinkedinIn, FaGithub, FaEnvelope, FaLaptop } from "react-icons/fa"
import Button from "./button"

const SocialLinks = styled.div`
    width: 100px;
    display: flex;
    justify-content: space-between;
    & a:hover {
      transition: color 1s ease;
      color: #4E58F5;
    }
`;

export default () => (
  <SocialLinks>
    <a href="mailto:sm@smrry.com?Subject=Website%20Enquiry" target="_blank"><FaEnvelope /></a>
    <a href="https://github.com/smrr723" target="_blank"><FaGithub /></a>
    <a href="https://www.linkedin.com/in/scomurray/" target="_blank"><FaLinkedinIn /></a>
    <a href="/consulting"><FaLaptop /></a>
  </SocialLinks>
)
